import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";
import Swal from "sweetalert2";
import { TermsModal } from "../packs/modals/termsModal";
import { EventOnboardingVideoModal } from "../packs/modals/eventOnboardingVideoModal";

export default class extends Controller {
  connect() {
    StimulusReflex.register(this);

    const modals = [];

    if (this.data.get("show-terms") === "true") {
      const tModal = new TermsModal(this.data.get("modal-html"));
      modals.push(tModal.termsModal());
    }

    if (this.data.get("show-event-onboarding") === "true" && this.data.get("event-onboarding-video")) {
      const eModal = new EventOnboardingVideoModal(this.data.get("event-onboarding-url"), this.data.get("event-onboarding-video"));
      modals.push(eModal.eventOnboardingModal());
    }

    Swal.queue(modals);
  }

  createUserEventTerm() {
    let eventId = this.data.get("event-id");
    let termId = this.data.get("term-id");

    this.stimulate("OnboardingEventTermsReflex#create", eventId, termId);
  }
}
