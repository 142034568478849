import { Controller } from "stimulus"

export default class extends Controller {
    connect() {
        this.toggleClasses();
    }

    toggleClasses() {
        const sidebar = document.querySelector('#sidebar');
        const contentSidebarToggler = document.querySelector('#content .sidebarCollapse');
        if (sidebar.classList.contains('active')) {
            sidebar.classList.remove('active');
            contentSidebarToggler.classList.add('hidden');
        } else {
            sidebar.classList.add('active');
            contentSidebarToggler.classList.remove('hidden');
        }
    }

    toggleSidebar(e) {
        e.preventDefault();
        this.toggleClasses();
    }
}
