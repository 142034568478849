import { Controller } from "stimulus"

export default class extends Controller {
    toggleHideSubLinks(event) {
        event.preventDefault();
        this.toggleClasses();
    }

    toggleClasses() {
        $(this.element).siblings('.nav-child-list').toggle(200);
    }
}
