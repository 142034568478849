import consumer from "./consumer"

consumer.subscriptions.create("EventChannel", {
  // Called when the subscription is ready for use on the server
  connected() {
  },

  // Called when the subscription has been terminated by the server
  disconnected() {
  },

  // Called when there's incoming data on the websocket for this channel
  received(data) {
    console.log(data);

    if (typeof data === 'string' || data instanceof String) {
      data = JSON.parse(data)
    }

    if (data.event_type === 'check_in_discovery_form_completed' ||
        data.event_type === 'check_in_video_call_completed') {
      window.location.href = data.redirect_url;
    }
  }
});
