import {Controller} from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
    if ($('.form_answers.new, .form_answers.create').length === 0) { return; }

    const draggableFields = document.querySelectorAll('.form_field_container.draggable');
    const dropAreaContainer = document.querySelectorAll('.drop_area_container');
    const optionsContainer = document.querySelectorAll('.draggable_options_container');
    const dropAreaText = document.querySelectorAll('.drop_area_text');

    draggableFields.forEach((_, i) => {
      new Sortable(dropAreaContainer[i], {
        group: `shared_${i}`,
        animation: 150,
        onAdd: function (_event) {
          dropAreaText[i].innerHTML = '';
        }
      });

      new Sortable(optionsContainer[i], {
        group: {
          name: `shared_${i}`,
          put: false
        },
        animation: 150,
        sort: false,
        onRemove: function (_event) {
          let div = draggableFields[i];
          if (optionsContainer[i].querySelectorAll('.draggable_option').length) {
            div.querySelector('.completeText').style.display = 'block';
          } else {
            div.querySelector('.completeText').style.display = 'none';
          }
        }
      });
    });
  }
}
