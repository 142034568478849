import SignaturePad from "signature_pad";

export class RealEliteModal {
  acceptButtonText = 'Accept';
  cancelButtonText = 'Cancel';
  titleText = 'Real Elite - Terms of Use';
  modalHtml;

  constructor(modalHtml) {
    this.modalHtml = modalHtml
  }

  termsModal() {
    return {
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: this.acceptButtonText,
      showCancelButton: true,
      cancelButtonText: this.cancelButtonText,
      html: this.modalHtml,
      title: this.titleText,
      didOpen: () => {
        this.addSignaturePad();
        this.disableAcceptButton();
        this.enableAcceptButtonWhenComplete();
      }
    };
  }

  addSignaturePad() {
    const _this = this;
    const canvas = $('#signature-canvas')[0];
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
    const signaturePad = new SignaturePad(canvas, { onEnd: () => _this.handlePopulateSignatureData($(canvas), signaturePad) });

    window.onresize = _this.resizeCanvas(canvas, signaturePad);
    _this.resizeCanvas(canvas, signaturePad);

    $('button.signature_pad_reset').on('click', function(event) {
      event.preventDefault();
      signaturePad.clear();
      $(this).siblings('[id="signature_pad_input"]').val('');
    });
  }

  handlePopulateSignatureData(canvas, signaturePad) {
    const data = signaturePad.toDataURL();
    canvas.siblings('[id="signature_pad_input"]').val(data);
  }

  resizeCanvas(canvas, signaturePad) {
    var ratio =  Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);
    signaturePad.clear(); // otherwise isEmpty() might return incorrect value
  }

  disableAcceptButton() {
    $(`.swal2-confirm.swal2-styled:contains(${this.acceptButtonText})`).attr('disabled', 'disabled');
  }

  enableAcceptButton() {
    $(`.swal2-confirm.swal2-styled:contains(${this.acceptButtonText})`).removeAttr('disabled');
    this.addDataReflexToAcceptButton();
  }

  enableAcceptButtonWhenComplete() {
    let _this = this;

    $('label[for="terms-checkbox"], #terms-checkbox, #signature-canvas, button.signature_pad_reset').on('click', function() {
      _this.formIsComplete() ? _this.enableAcceptButton() : _this.disableAcceptButton();
    });

    // add event handler to signature pad for touchscreen devices: https://stackoverflow.com/a/52855084
    if (window.matchMedia('(pointer: coarse)').matches) {
      $('#signature-canvas').on('touchend', function() {
        _this.formIsComplete() ? _this.enableAcceptButton() : _this.disableAcceptButton();
      });
    }

    $('#name-field').on('input', function() {
      _this.formIsComplete() ? _this.enableAcceptButton() : _this.disableAcceptButton();
    });
  }

  formIsComplete() {
    return this.checkboxChecked() && this.namePresent() && this.signaturePresent();
  }

  checkboxChecked() {
    return $('#terms-checkbox').is(':checked');
  }

  namePresent() {
    return $.trim($('#name-field').val());
  }

  signaturePresent() {
    const canvas = $('#signature-canvas')[0];
    const context = canvas.getContext('2d');
    const pixelBuffer = new Uint32Array(
      context.getImageData(0, 0, canvas.width, canvas.height).data.buffer
    );
    return pixelBuffer.some(color => color !== 0);
  }

  addDataReflexToAcceptButton() {
    let termsOfUse = $('#real-elite-terms-of-use').html();
    let name = $('#name-field').val();
    let eventId = $('body').attr('data-event-id');
    let signature = $('#signature_pad_input').val();

    $(`.swal2-confirm.swal2-styled:contains(${this.acceptButtonText})`).attr({
      'data-controller': 'real-elite',
      'data-action': 'click->real-elite#createAgreementRecord',
      'data-real-elite-terms-of-use': `${termsOfUse}`,
      'data-real-elite-name': `${name}`,
      'data-real-elite-event-id': `${eventId}`,
      'data-real-elite-signature': `${signature}`
    });
  }
}
