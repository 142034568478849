require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery");
require("expose-loader?$!jquery");
require("expose-loader?jQuery!jquery");
require("bootstrap/dist/js/bootstrap")
require("chartkick")
require("chart.js")
const moment = require('moment')

import('./application.css');

import "controllers"
