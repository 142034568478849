import axios from "axios";

export class EventOnboardingVideoModal {
    dontShow = 'dont_show';
    dontShowButtonText = 'Mark Watched';
    onboardingCompletedUrl = '';
    onboardingVideoUrl = '';

    constructor(onboardingCompletedUrl, onboardingVideoUrl) {
        this.onboardingCompletedUrl = onboardingCompletedUrl;
        this.onboardingVideoUrl = onboardingVideoUrl;
    }

    addHandlersForButtonVideo() {
        let _this = this;
        let $dontShowButton = $('.swal2-confirm.swal2-styled:contains("' + this.dontShowButtonText + '")');
        $dontShowButton.on('click', function () {
            _this.handleChoiceForEventVideo(_this.dontShow, _this.onboardingCompletedUrl);
        })
    }

    handleChoiceForEventVideo(value, url) {
        if (value === this.dontShow) {
            axios
                .post(url, {}, {
                    headers: {
                        'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content
                    }
                })
        }
    }

    eventOnboardingModal() {
        return {
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            customClass: {
                container: 'swal2-event-video'
            },
            cancelButtonText: 'Close',
            confirmButtonText: this.dontShowButtonText,
            html: this.eventVideoHtmlForVideo(),
            showCancelButton: true,
            title: 'Onboarding Pre-Check-In',
            onOpen: () => this.addHandlersForButtonVideo(),
        };
    }

    eventVideoHtmlForVideo() {
        let div = document.createElement('div');
        div.setAttribute('style', 'padding:56.25% 0 0 0;position:relative;')

        let iframe = document.createElement('iframe');
        iframe.setAttribute('style', 'position:absolute;top:0;left:0;width:100%;height:100%;');
        iframe.setAttribute('frameborder', '0');
        iframe.setAttribute('allow', 'autoplay; fullscreen');
        iframe.setAttribute('allowfullscreen', 'true');
        iframe.setAttribute('src', 'https://player.vimeo.com/video/' + this.onboardingVideoUrl);

        let script = document.createElement('script');
        script.setAttribute('src', 'https://player.vimeo.com/api/player.js')

        div.appendChild(iframe);
        div.appendChild(script);

        return div;
    }
}
