import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";
import Swal from 'sweetalert2';
import { RealEliteModal } from "../packs/modals/realEliteModal";
import consumer from "../channels/consumer";
import CableReady from "cable_ready";

export default class extends Controller {
  connect() {
    StimulusReflex.register(this);

    this.channel = consumer.subscriptions.create('EventChannel', {
      received(data) {
        if (data.cableReady) CableReady.perform(data.operations);
      }
    });

    document.addEventListener('activate_real_elite_success', this.showResponseModal);
    document.addEventListener('activate_real_elite_failure', this.showResponseModal);
  }

  disconnect() {
    this.channel.unsubscribe();
    document.removeEventListener('activate_real_elite_success', this.showResponseModal);
    document.removeEventListener('activate_real_elite_failure', this.showResponseModal);
  }

  showModal(event) {
    event.preventDefault();

    const realEliteModal = new RealEliteModal(this.data.get('modal-html'));
    Swal.fire(realEliteModal.termsModal());
  }

  createAgreementRecord() {
    let termsOfUse = this.data.get('terms-of-use');
    let name = this.data.get('name');
    let eventId = this.data.get('event-id');
    let signature = this.data.get('signature');

    this.stimulate('RealEliteTermsReflex#create', termsOfUse, name, eventId, signature);
  }

  showResponseModal(event) {
    const { html, icon } = event.detail;
    Swal.fire({
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      title: '',
      html: html,
      icon: icon
    }).then(() => { if (icon !== 'error') $('#real-elite-nav-item a').hide() });
  }
}
