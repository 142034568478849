import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    if (this.data.get('should-track') === 'false') {
      return;
    }

    TimeMe.initialize({
      currentPageName:       "my-home-page",
      trackWhenUserGoesIdle: false
    })

    window.onbeforeunload = function (event) {
      let xmlhttp = new XMLHttpRequest();
      xmlhttp.open("POST", "ENTER_URL_HERE", true);
      xmlhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
      let timeSpentOnPage = TimeMe.getTimeOnCurrentPageInSeconds();
      xmlhttp.send(timeSpentOnPage);
    };
  }
}
