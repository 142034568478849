export class TermsModal {
  acceptButtonText = "ACCEPT";
  logOutButtonText = "LOG OUT";
  modalHtml;

  constructor(modalHtml) {
    this.modalHtml = modalHtml;
  }

  termsModal() {
    return {
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      customClass: {
        container: "swal2-terms",
      },
      confirmButtonText: this.acceptButtonText,
      showCancelButton: true,
      cancelButtonText: this.logOutButtonText,
      html: this.modalHtml,
      title: "Terms and Conditions",
      backdrop: false,
      didOpen: () => {
        this.disableAcceptButton();
        this.enableLogOutButton();
        this.addHandlerForCheckBoxSelect();
        this.addDataReflexToAcceptButton();
        this.addCutsomBackdrop();
      },
      didClose: () => {
        this.removeCustomBackdrop();
      }
    };
  }

  disableAcceptButton() {
    $(`.swal2-confirm.swal2-styled:contains(${this.acceptButtonText})`).attr("disabled", "disabled");
  }

  enableLogOutButton() {
    $(`.swal2-cancel.swal2-styled:contains(${this.logOutButtonText})`).on("click", function() {
       window.location = "/users/logout";
    });
  }

  addHandlerForCheckBoxSelect() {
    let $acceptButton = $(`.swal2-confirm.swal2-styled:contains(${this.acceptButtonText})`);

    $("#terms-checkbox").on("change", function () {
      if (this.checked) {
        $acceptButton.removeAttr("disabled");
      } else {
        $acceptButton.attr("disabled", "disabled");
      }
    });
  }

  addDataReflexToAcceptButton() {
    let eventId = $("body").data("event-id");
    let termId = $("#terms-container").data("term-id");

    $(`.swal2-confirm.swal2-styled:contains(${this.acceptButtonText})`).attr({
      "data-controller": "event-onboarding",
      "data-action": "click->event-onboarding#createUserEventTerm",
      "data-event-onboarding-event-id": `${eventId}`,
      "data-event-onboarding-term-id": `${termId}`
    });
  }

  addCutsomBackdrop() {
    $("<div class='backdrop'></div>").insertBefore("div.swal2-container.swal2-terms");
    $("#header, section.right_container").css({"opacity":"0.5"});
    $("#menuToggle input").attr("disabled", "disabled");
    $("body").css({"overflow":"hidden"});
  }

  removeCustomBackdrop() {
    $(".backdrop").remove();
    $("#header, section.right_container").css({"opacity":"1"});
    $("#menuToggle input").removeAttr("disabled");
    $("body").css({"overflow":"auto"});
  }
}
