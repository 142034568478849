import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    if ($('.form_answers.new, .form_answers.create').length === 0) { return; }

    this.performValidations();
  }

  performValidations() {
    let stringFields = [];
    let textFields = [];
    let numberFields = [];
    let selectableFields = [];
    let checkableFields = [];
    let rateableFields = [];
    let signatureFields = [];
    let consentableFields = [];
    let draggableFields = [];
    const STRING = 'string';
    const TEXT = 'text';
    const NUMBER = 'number';
    const SELECTABLE = 'selectable';
    const CHECKABLE = 'checkable';
    const RATEABLE = 'rateable';
    const SIGNATURE = 'signature';
    const CONSENTABLE = 'consentable';
    const DRAGGABLE = 'draggable';
    
    $('.form_field_container').each((_, field) => {
      const FIELD_TYPE = field.className.split(' ')[1];
      if (field.querySelector('.form_label').textContent.includes('(required)')) {
        switch (FIELD_TYPE) {
          case STRING:
            stringFields.push(field);
            break;
          case TEXT:
            textFields.push(field);
            break;
          case NUMBER:
            numberFields.push(field);
            break;
          case SELECTABLE:
            selectableFields.push(field);
            break;
          case CHECKABLE:
            checkableFields.push(field);
            break;
          case RATEABLE:
            rateableFields.push(field);
            break;
          case SIGNATURE:
            signatureFields.push(field);
            break;
          case CONSENTABLE:
            consentableFields.push(field);
            break;
          case DRAGGABLE:
            field.append(helpTextElement('block'));
            draggableFields.push(field);
            break;
          default:
            break;
        }
      } else {
        if (FIELD_TYPE == DRAGGABLE) {
          field.append(helpTextElement('none'));
        }
      }
    });

    // remove chars when inputted into number field
    $(".number input[type='number']").each((_, input) => {
      input.oninput = (e) => { e.target.value = e.target.value.replace(/[^0-9]/g,""); }
    });
    
    $('form').on('submit', function(e) {
      if (anyIncompleteNonRequiredDraggableFields()) { 
        e.preventDefault(); 
        alert('Please use all draggable options.'); 
      } else if (anyBlankRequiredFields()) { 
        e.preventDefault(); 
        alert('Please answer all required fields.'); 
      } else { 
        adjustDraggableFieldElements(); 
      }
    });

    function anyBlankRequiredFields() {
      if (anyBlankFields(stringFields, STRING)) { return true; }
      if (anyBlankFields(textFields, TEXT)) { return true;}
      if (anyBlankFields(numberFields, NUMBER)) { return true; }
      if (anyBlankFields(selectableFields, SELECTABLE)) { return true; }
      if (anyBlankFields(rateableFields, RATEABLE)) { return true; }
      if (anyBlankFields(signatureFields, SIGNATURE)) { return true; }
      if (anyBlankFields(consentableFields, CONSENTABLE)) { return true; }
      if (anyBlankFields(checkableFields, CHECKABLE)) { return true; }
      if (anyBlankFields(draggableFields, DRAGGABLE)) { return true; }
      return false;
    }

    function anyBlankFields(fields, FIELD_TYPE) {
      if (fields.length === 0) { return false; }

      var anyBlank = false;
      fields.forEach(field => {
        switch (FIELD_TYPE) {
          case STRING:
          case NUMBER:
          case TEXT:
          case SELECTABLE:
          case RATEABLE:
            if (field.querySelector('input').value == '') { anyBlank = true; }
            break;
          case SIGNATURE:
            if (canvasIsBlank(field.querySelector('canvas'))) { anyBlank = true; }
            break;
          case CONSENTABLE:
            if (field.querySelector('input:checked') == null) { anyBlank = true; }
            break;
          case CHECKABLE:
            if (field.querySelectorAll('.check_boxes input:checked').length === 0) { anyBlank = true; }
            break;
          case DRAGGABLE:
            if (anyUnusedRequiredOptions(field.querySelector('.draggable_options_container'))) { anyBlank = true; }
            break;
          default:
            break;
        }
        if (anyBlank) { return false; }
      });
      return anyBlank;
    }

    // https://stackoverflow.com/questions/17386707/how-to-check-if-a-canvas-is-blank
    function canvasIsBlank(canvas) {
      const context = canvas.getContext('2d');
      const pixelBuffer = new Uint32Array(
        context.getImageData(0, 0, canvas.width, canvas.height).data.buffer
      );
      return !pixelBuffer.some(color => color !== 0);
    }

    function adjustDraggableFieldElements() {
      if ($('.draggable').length === 0) { return; }
      
      // remove input element from unanswered non-required draggable field options
      if ($(".draggable .form_label:not(:contains('(required)'))").length) { 
        const nonRequiredDraggableFields = $(".draggable .form_label:not(:contains('(required)'))").parent();
        nonRequiredDraggableFields.each((_, container) => { 
          const options = container.querySelectorAll('.draggable_options_container .draggable_option');
          if (options.length) {
            options.forEach(option => {
              option.querySelector('input').remove();
            });
          }
        });
      }
    
      // append input element to all answered draggable field options
      if ($('.draggable_options_container:not(:has(*))').parent().find('.draggable_option').length) {
        const answeredDraggableFields = $('.draggable_options_container:not(:has(*))').parent().find('.draggable_option');
        answeredDraggableFields.each((_, option) => {
          const answerName = option.querySelector('input').getAttribute('name').match(/form_answer\[(.*)\[field_answer_label\]/)[1];
          var input = document.createElement('input');
          input.setAttribute('name', `form_answer[${answerName}[field_additional_information1]]`);
          input.setAttribute('value', 'true');
          input.setAttribute('type', 'hidden');

          option.append(input);
        });
      }
    }

    // returns true if required draggable field is not fully answered, else returns false
    function anyUnusedRequiredOptions(options) {
      return !!options.querySelectorAll('.draggable_option').length
    }

    // returns true if any non-required draggable fields are partially answered, else returns flase
    function anyIncompleteNonRequiredDraggableFields() {
      if ($(".draggable .form_label:not(:contains('(required)'))").length === 0) { return; }

      const nonRequiredDraggableFields = $(".draggable .form_label:not(:contains('(required)'))").parent();
      const dropAreaContainer = nonRequiredDraggableFields.find('.drop_area_container');
      const optionsContainer = nonRequiredDraggableFields.find('.draggable_options_container');
      var anyIncomplete = false;
      nonRequiredDraggableFields.each((i, _) => {
        if (optionsContainer[i].querySelectorAll('.draggable_option').length && dropAreaContainer[i].querySelectorAll('.draggable_option').length) {
          anyIncomplete = true;
          return false;
        }
      });
      return anyIncomplete;
    }

    function helpTextElement(display) {
      const el = document.createElement('div');
      el.classList.add('completeText');
      if (window.matchMedia('only screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait)').matches) {
        el.innerHTML = '<small><b>Please use all of the provided options above.</b></small>';
      } else {
        el.innerHTML = '<small><b>Please use all of the provided options on the right.</b></small>';
      }
      el.style.display = display;
      return el;
    }
  }
}
