export class AdvisorIntroVideoModal {
  videoVimeoID = '';

  constructor(videoVimeoID) {
    this.videoVimeoID = videoVimeoID;
  }

  introVideoModal() {
    return {
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: 'Close',
      html: this.advisorIntroVideoHtmlForVideo(),
      title: 'Meet Your Advisor!'
    };
  }

  advisorIntroVideoHtmlForVideo() {
    let div = document.createElement('div');
    div.setAttribute('style', 'padding:56.25% 0 0 0;position:relative;')

    let iframe = document.createElement('iframe');
    iframe.setAttribute('style', 'position:absolute;top:0;left:0;width:100%;height:100%;');
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allow', 'autoplay; fullscreen');
    iframe.setAttribute('allowfullscreen', 'true');
    iframe.setAttribute('src', 'https://player.vimeo.com/video/' + this.videoVimeoID);

    let script = document.createElement('script');
    script.setAttribute('src', 'https://player.vimeo.com/api/player.js')

    div.appendChild(iframe);
    div.appendChild(script);

    return div;
  }
}
