import {Controller} from "stimulus"

export default class extends Controller {
    initialize() {}

    connect() {
        $(".tablinks").click(function () {
            let value = $(this).attr("name");
            let parent = $(this).attr("parent");

            $("." + parent + " .tablinks").removeClass("active");
            $(this).addClass("active");

            $("." + parent + " .tabcontent").removeClass("active");
            $("#" + value).addClass("active");
        });

        $(".collapse_left_bar").click(function () {
            $('.main_content').toggleClass("full_left");
            $('.left_bar').toggleClass("full_left");
            $(".collapse_left_bar .fa").toggleClass('fa-caret-left');
            $(".collapse_left_bar .fa").toggleClass('fa-caret-right');
            $(".left_container").fadeToggle(100);
        });
        $(".collapse_right_bar").click(function () {
            $('.main_content').toggleClass("full_right");
            $('.right_bar').toggleClass("full_right");
            $(".collapse_right_bar .fa").toggleClass('fa-caret-right');
            $(".collapse_right_bar .fa").toggleClass('fa-caret-left');
            $(".right_container").fadeToggle(100);
        });

        var x = window.matchMedia("(max-width: 1170px)")
        this.runtimeLoad(x) // Call listener function at run time
        x.addListener(this.runtimeLoad) // Attach listener function on state changes
    }

    runtimeLoad(x) {
        if (x.matches) { // If media query matches
            $('.main_content').toggleClass("full_right");
            $('.right_bar').toggleClass("full_right");
            $(".collapse_right_bar .fa").toggleClass('fa-caret-right');
            $(".collapse_right_bar .fa").toggleClass('fa-caret-left');
            $(".right_container").fadeToggle(100);

            $('.main_content').toggleClass("full_left");
            $('.left_bar').toggleClass("full_left");
            $(".collapse_left_bar .fa").toggleClass('fa-caret-left');
            $(".collapse_left_bar .fa").toggleClass('fa-caret-right');
            $(".left_container").fadeToggle(100);
        }
    }

}
