import {Controller} from "stimulus"
import axios from "axios";

export default class extends Controller {
    initialize() {
        ZoomMtg.setZoomJSLib('https://dmogdx0jrul3u.cloudfront.net/1.9.9/lib', '/av');
        ZoomMtg.preLoadWasm();
        ZoomMtg.prepareJssdk();
    }

    connect() {
        this.joinMeeting();
    }

    joinMeeting() {
        this.getSignature({
            apiKey: this.data.get('api-key'),
            meetingNumber: this.data.get('meeting-number'),
            leaveUrl: this.data.get('leave-url'),
            userName: this.data.get('user-name'),
            userEmail: this.data.get('user-email'),
            passWord: this.data.get('password'),
            role: 0
        });
    }

    getSignature(meetConfig) {
        axios.post('/zoom/generate_signature',
            {meetingData: meetConfig}
        )
            .then(response => {
                ZoomMtg.init({
                    leaveUrl: meetConfig.leaveUrl,
                    isSupportAV: true,
                    success: function () {
                        console.log('success')
                        ZoomMtg.join({
                            signature: response.data.signature,
                            apiKey: meetConfig.apiKey,
                            meetingNumber: meetConfig.meetingNumber,
                            userName: meetConfig.userName,
                            userEmail: meetConfig.userEmail,
                            passWord: meetConfig.passWord,
                            error(res) {
                                console.log(res);
                            }
                        })
                    }
                })
            })
    }
}
