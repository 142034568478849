import {Controller} from "stimulus"
import SignaturePad  from "signature_pad";

export default class extends Controller {
    connect() {
        this.addCheckBoxLabelClickEvents();
        this.addSignaturePads();
    }

    addCheckBoxLabelClickEvents() {
        $('form.form_answers input[type=checkbox]').each(function(index, value) {
            $(value).siblings('label').on('click', function() {
                let checkbox = $(this).siblings('input[type=checkbox]')
                checkbox.prop('checked', !checkbox.prop('checked'));
            })
        })
    }

    addSignaturePads() {
        const _this = this;
        $('form.form_answers canvas').each(function(index, value) {
            const canvas = value;
            const $canvas = $(value);
            canvas.width = canvas.offsetWidth;
            canvas.height = canvas.offsetHeight;
            const signaturePad = new SignaturePad(
                canvas,
                {
                    onEnd: () => _this.handlePopulateSignatureData(canvas, signaturePad)
                }
        )

            window.onresize = _this.resizeCanvas(canvas, signaturePad)
            _this.resizeCanvas(canvas, signaturePad)

            $canvas.siblings('.signature_pad_reset').on('click', function(event){
                event.preventDefault();
                signaturePad.clear();
                $canvas.siblings('.signature_pad_input').val('')
                $canvas.siblings('.signature_pad_answer_label').val('')
            })
        })

    }

    resizeCanvas(canvas, signaturePad) {
        var ratio =  Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);
        signaturePad.clear(); // otherwise isEmpty() might return incorrect value
    }

    handlePopulateSignatureData(canvas, signaturePad) {
        const data = signaturePad.toDataURL();
        $(canvas).siblings('.signature_pad_input').val(data)
        $(canvas).siblings('.signature_pad_answer_label').val('signature')
    }
}
