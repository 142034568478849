import { Controller } from "stimulus"
import SmoothScroll from 'smooth-scroll';

export default class extends Controller {
    static targets = ['button', 'truncated', 'full']

    readMore() {
        this.truncatedTargets[0].classList.toggle('hidden')
        this.fullTargets[0].classList.toggle('hidden')

        if (this.buttonTargets[0].innerText === 'Hide') {
            this.buttonTargets[0].innerText = 'Read More';
        } else {
            this.buttonTargets[0].innerText = 'Hide';
        }

        (new SmoothScroll(
            'a[href*="#"]',
            {
                speed: 800,
                speedAsDuration: true
            }
        )).animateScroll(this.element);
    }
}
