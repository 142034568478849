import { Controller } from "stimulus"
import Player         from "@vimeo/player/dist/player";

export default class extends Controller {
  connect() {
    setTimeout(() => {
      var player = new Player(document.querySelector('iframe'));
      var videoId = this.data.get('video-id');

      this.getDateVisted(player, videoId);
      this.getDateCompleted(player, videoId);
    }, 100);
  }

  // record date when landed on video page
  getDateVisted(player, videoId) {
    player.ready().then(function() {
      $.ajax({
        method: 'PATCH',
        dataType: 'json',
        data: { date_visited: Date.now()/1000 },
        url: `${videoId}/update_user_specific_video`,
        headers: { 'X-CSRF-Token': $("meta[name='csrf-token']").attr('content') }
      })
      .done(function(data, textStatus, jqXHR) {})
      .fail(function(data, textStatus, jqXHR) {});
    });
  }

  // record date when video watched all the way through
  getDateCompleted(player, videoId) {
    player.on('ended', function() {
      if ($('.event-videos-content i').length === 0) {
        document.querySelector('.event-videos-content h3').innerHTML += "<i class='fa fa-check-circle completed' aria-label='complete'></i>";
      }

      $.ajax({
        method: 'PATCH',
        dataType: 'json',
        data: { date_completed: Date.now()/1000 },
        url: `${videoId}/update_user_specific_video`,
        headers: { 'X-CSRF-Token': $("meta[name='csrf-token']").attr('content') }
      })
      .done(function(data, textStatus, jqXHR) {})
      .fail(function(data, textStatus, jqXHR) {});
    });
  }
}
