import {Controller} from "stimulus"
import Tooltip from 'tether-tooltip'

export default class extends Controller {
    connect() {
        new Tooltip({
            target: this.element,
            content: this.data.get('message'),
            classes: 'tooltip-tether-arrows',
            position: this.data.get('position')
        })
    }
}
