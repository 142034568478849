import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['focus']

  submit (e) {
    e.preventDefault()
    this.stimulate('UserGoalsFormReflex#submit').then(() => {
      this.element.reset()
      this.focusTarget.focus()
    })
  }
}
