import {Controller} from "stimulus"
import Swal from 'sweetalert2'

import {AdvisorIntroVideoModal} from "../packs/modals/advisorIntroVideoModal";

export default class extends Controller {

  showModal() {
    const iModal = new AdvisorIntroVideoModal(this.data.get('video-vimeo-id'));
    Swal.fire(iModal.introVideoModal());
  }

}
